<template>
  <div class="siyu-select">
    <div class="siyu-select-selection" ref="selection" :tabindex="0" :hidefocus="true">
      <div class="siyu-select-box"  :ref="'clickWrapper_' + clickWrapperCode">
        <div class="siyu-select-placeholder" @click="handleClickBox" v-show="!inSearch && !valueLabel">
          {{ placeholder }}
        </div>
        <div class="siyu-select-value-label" @click="handleClickBox" v-show="!inSearch && valueLabel">
          {{ valueLabel }}
        </div>
        <div class="siyu-select-search" v-show="inSearch">
          <input
            ref="search"
            @blur="handleBlur"
            v-model="searchValue"
            @input="onSearch"
            :placeholder="searchPlaceholder"
          />
        </div>
      </div>
      <a-icon class="siyu-select-down" :class="{ 'siyu-select-down_rotate': visible }" type="down" />
      <options
        ref="iconWrapper"
        :placeholder="placeholder"
        :valueKey="valueKey"
        :labelKey="labelKey"
        :options="list"
        :visible="visible"
        :offset="offset"
        :top="position.top"
        :left="position.left"
        :spinning="spinning"
        :loadingText="loadingText"
        @click="handleClick"
      >
        <div class="siyu-menu-item" slot-scope="item">
          <slot name="menuItem" v-bind="item">
            {{ item[labelKey] }}
          </slot>
        </div>
      </options>
    </div>
  </div>
</template>

<script>
import Options from './Options'
import getOffset from '@/utils/getOffset'
export default {
  components: {
    Options
  },

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },

    showSearch: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      default: () => []
    },

    valueKey: {
      type: String,
      default: 'value'
    },

    labelKey: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    spinning: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: '加载中...'
    },
    searchKey: {
      type: [String, Array],
      default: null
    }
  },

  data() {
    return {
      position: {
        top: 0,
        left: 0
      },
      offset: {
        top: 0,
        left: 0
      },
      visible: false,
      inSearch: false,
      valueLabel: '',
      searchValue: '',
      clickWrapperCode: '',
      clickWrapperFuc: () => {}
    }
  },

  watch: {
    value(value) {
      if (value === undefined || value === null) {
        this.valueLabel = ''
      } else {
        this.$nextTick(() => {
          let target = this.options.find(item => item[this.valueKey] == value)
          if (target) {
            this.valueLabel = target[this.labelKey]
          }
        })
      }
    }
  },

  computed: {
    list() {
      if (this.searchValue) {
        let patt = new RegExp(this.searchValue, 'g')
        let list = this.options.filter(item => {
          if (this.searchKey) {
            if (Array.isArray(this.searchKey)) {
              return this.searchKey.some(text => patt.test(item[text]))
            } else {
              return patt.test(item[this.labelKey])
            }
          } else {
            let sitem = item[this.labelKey] + ''
             if(sitem.indexOf(this.searchValue) !== -1){
              return item
            }
            
          }
        })
        return list
      } else {
        return this.options
      }
    }
  },

  methods: {
    onSearch() {},

    handleClose() {
      this.visible = false
    },

    show() {
      this.visible = !this.visible
      if (this.visible) {
        let el = this.$el
        let h = el.offsetHeight || el.clientHeight
        let w = el.offsetWidth || el.clientWidth

        let offset = getOffset(el)
        offset.top += h
        this.offset.left = w
        this.position = offset
      }
    },

    handleClick(item) {
      this.visible = false
      this.inSearch = false
      this.searchValue = ''
      this.$emit('select', item)
      this.$emit('change', item[this.valueKey])
    },

    handleClickBox() {
      this.show()
      if (this.showSearch) {
        this.inSearch = true
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      }
    },

    handleBlur() {
      this.inSearch = false
    }
  },

  created() {
    let _this = this
    this.clickWrapperCode = new Date().getTime()
    this.clickWrapperFuc = function(e) {
      let refClick = _this.$refs['clickWrapper_' + _this.clickWrapperCode].contains(e.target) //自身盒子
      let refIcon = _this.$refs.iconWrapper.$el.contains(e.target) //点击出现盒子的盒子
      if (!refClick && !refIcon) {
        _this.visible = false
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.clickWrapperFuc)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.clickWrapperFuc, false)
  }
}
</script>

<style lang="less" scoped>
.siyu-select {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(22, 2, 2, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
  .siyu-select-selection {
    position: relative;
    background-color: #fff;
    border: 1px solid #0e0c0c;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    &:hover {
      border-color: rgba(0, 0, 0, 0.65);
      border-right-width: 1px !important;
    }
    &:focus {
      border-color: rgba(0, 0, 0, 0.65);
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px fade(rgba(0, 0, 0, 0.65), 20);
    }
    .siyu-select-down {
      display: inline-block;
      color: inherit;
      font-style: normal;
      line-height: 0;
      text-align: center;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      position: absolute;
      top: 50%;
      right: 11px;
      margin-top: -6px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 12px;
      line-height: 1;
      transform-origin: 50% 50%;
      transition: all 0.2s;
    }
    .siyu-select-down_rotate {
      transform: rotate(-180deg);
    }
  }
}
.siyu-select-box {
  margin-left: 11px;
  margin-right: 24px;
  height: 100%;
  .siyu-select-placeholder {
    color: #bfbfbf;
     height: 35px;
  }

  .siyu-select-search {
    width: 100%;
    height: 100%;
    input {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
    }
    input::-webkit-input-placeholder {
      color: #272323;
    }
    input::-moz-input-placeholder {
      color: #292424;
    }
    input::-ms-input-placeholder {
      color: #131111;
    }
  }
}
</style>
