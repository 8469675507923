<template>
  <div v-show="visible" class="siyu-dropdown-plane" :style="{ 'min-width': `${offset.left}px`, ...positionStyle }">
    <div class="siyu-select-loading" v-show="spinning">
      <a-spin :spinning="spinning"></a-spin>
      <span class="siyu-select-loading_text">{{ loadingText }}</span>
    </div>
    <div class="siyu-dropdown-menus" v-show="!spinning">
      <div class="siyu-dropdown-menu-item" v-for="item in list" :key="item[valueKey]" @click="handleClick(item)">
        <slot v-bind="item">
          <span>{{ item[labelKey] }}</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    offset: {
      type: Object,
      default: () => {
        return { top: 0, left: 0 }
      }
    },

    visible: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      default: () => []
    },

    valueKey: {
      type: String,
      default: 'value'
    },

    labelKey: {
      type: String,
      default: 'label'
    },

    spinning: {
      type: Boolean,
      default: false
    },

    loadingText: {
      type: String,
      default: '加载中...'
    }
  },

  data() {
    return {
      width: 0,
      height: 0,
      list: this.options,
      searchValue: ''
    }
  },

  computed: {
    positionStyle() {
      let bodyW = document.body.scrollWidth || document.body.clientWidth || document.body.offsetWidth

      let l = {
        top: `${this.top + 5}px`,
        left: `${this.left}px`
      }
      let r = {
        top: `${this.top + 5}px`,
        right: `${bodyW - (this.left + this.offset.left)}px`
      }
      return bodyW / 2 > this.left ? l : r
    }
  },

  watch: {
    options: {
      handler: function(nv, ov) {
         nv && (this.list = JSON.parse(JSON.stringify(nv)))
      },
      immediate: true
    }
  },

  methods: {
    handleClick(value) {
      this.$emit('click', value)
    }
  },

  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector('body')
      if (body.append) {
        body.append(this.$el)
      } else {
        body.appendChild(this.$el)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.siyu-dropdown-plane {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  z-index: 9999;
  overflow: hidden;
  .siyu-dropdown-menus {
    max-height: 300px;
    overflow-y: scroll;
    .siyu-dropdown-menu-item {
      padding: 7px 10px;
      color: #333;
      background-color: #fff;
      cursor: pointer;
      transition: background-color 0.2s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
      ~ .siyu-dropdown-menu-item {
        border-top: 1px solid #f5f5f5;
      }
    }
  }
}
.siyu-select-loading {
  padding: 10px 15px;
  .siyu-select-loading_text {
    color: #999;
    padding-left: 10px;
  }
}
</style>
