<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    width="80%"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-tabs @change="callback">
      <a-tab-pane key="1" tab="客户信息">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="客户姓名" prop="name">
                <a-input
                  v-model="rowData.name"
                  placeholder="客户姓名"
                  :disabled="handle === 'add' ? false : true"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="手机号" prop="phone">
                <a-input
                  v-model="rowData.phone"
                  placeholder="手机号"
                  :disabled="handle === 'add' ? false : true"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!--            <a-col :span="12">-->
            <!--              <a-form-model-item label="客户类型" prop="customerType">-->
            <!--                <a-select placeholder="请选择" v-model="rowData.customerType"  :disabled="handle === 'add' ? false : true">-->
            <!--                  <a-select-option :value="item.id" v-for="(item, index) in customerTypeList" :key="index">-->
            <!--                    {{ item.title }}-->
            <!--                  </a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
            <a-col :span="12">
              <a-form-model-item label="客户来源" prop="customerSource">
                <a-select
                  placeholder="请选择"
                  v-model="rowData.customerSource"
                  :disabled="handle === 'add' ? false : true"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in customerSourceList" :key="index"
                    >【{{ item.customerName }}】{{ item.companyName }}-{{ item.channelType }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="订单类型" prop="orderType">
                <a-radio-group v-model="rowData.orderType" :disabled="handle === 'add' ? false : true">
                  <a-radio :value="1"> 零售 </a-radio>
                  <a-radio :value="2"> 项目 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="rowData.orderType == 2">
              <a-form-model-item label="项目名称" :prop="rowData.orderType == 2 ? 'entryName' : ''">
                <a-input
                  v-model="rowData.entryName"
                  placeholder="项目名称"
                  :disabled="handle === 'add' ? false : true"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="所属门店" prop="storeId" :autoLink="false" ref="item">
                <a-select
                  placeholder="请选择"
                  v-model="rowData.storeId"
                  :disabled="handle === 'add' ? false : true"
                  @change="onStoreChange"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in followerList" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所属员工" prop="managerId">
                <a-select placeholder="请选择" v-model="rowData.managerId" :disabled="handle === 'add' ? false : true">
                  <a-select-option :value="item.id" v-for="(item, index) in managerList" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="性别" prop="(gender">
                <a-radio-group v-model="rowData.gender" :disabled="handle === 'add' ? false : true">
                  <a-radio :value="0"> 男 </a-radio>
                  <a-radio :value="1"> 女 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="生日" prop="(birthday">
                <DatePicker :startTime.sync="rowData.birthday" :disabled="handle === 'add' ? false : true"></DatePicker>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="爱好" prop="(hobby">
                <a-input
                  v-model="rowData.hobby"
                  placeholder="请输入爱好"
                  :disabled="handle === 'add' ? false : true"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="(remark" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-textarea
                  v-model="rowData.remark"
                  placeholder="请输入"
                  :auto-size="{ minRows: 3, maxRows: 21 }"
                  :disabled="handle === 'add' ? false : true"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="物业信息">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-row>
            <a-col :span="12" v-if="rowData.orderType == 1">
              <a-form-model-item label="物业类型" prop="">
                <a-radio-group v-model="rowData.propertyType" :disabled="handle === 'add' ? false : true">
                  <a-radio :value="0"> 商品房 </a-radio>
                  <a-radio :value="1"> 自建房 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所属地址" :prop="dataList.length > 0 ? 'provinceId' : ''">
                <PCD
                  ref="pcd"
                  placeholder="请选择区域"
                  :province.sync="rowData.province"
                  :city.sync="rowData.city"
                  :district.sync="rowData.area"
                  :provinceId.sync="rowData.provinceId"
                  :cityId.sync="rowData.cityId"
                  :districtId.sync="rowData.areaId"
                  :disabled="handle === 'add' ? false : true"
                >
                </PCD>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" v-if="rowData.propertyType == 0">
              <a-form-model-item label="小区" :prop="dataList.length > 0 ? 'village' : ''">
                <!-- <a-input-group>
                  <a-input v-model="rowData.village" disabled style="width: 80%" placeholder="请输入"></a-input>
                  <a @click="onShowChooseCell" style="margin-top: 5px; display: block">选择小区</a>
                </a-input-group> -->
                <a-input v-model="rowData.village" :disabled="rowData.village == '' ? true : false">
                  <a-icon slot="addonAfter" type="search" style="width: 45px" @click="onShowChooseCell" />
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="rowData.propertyType == 1 && rowData.orderType == 1">
              <a-form-model-item label="房屋图片">
                <QiniuUpload
                  type="image"
                  :value.sync="pictureUrl"
                  :size="5"
                  :disabled="handle === 'add' ? false : true"
                ></QiniuUpload>
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="详细地址" prop="" v-if="rowData.propertyType == 1">
                <a-input
                  v-model="rowData.address"
                  placeholder="详细地址"
                  :disabled="handle === 'add' ? false : true"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="buildingTypeBindList.length > 0 && rowData.propertyType == 0 && rowData.village != ''">
            <a-col :span="24">
              <a-form-model-item label="楼层类型" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-radio-group
                  v-model="rowData.buildingTypeId"
                  :disabled="handle === 'add' ? false : true"
                  @change="changeHouse"
                >
                  <a-radio :value="item.buildingTypeId" v-for="(item, index) in houseTypeList" :key="index">
                    {{ item.buildingTypeName }}</a-radio
                  >
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row
            v-if="rowData.buildingTypeId != undefined && rowData.propertyType == 0 && describeDetailList.length > 0"
          >
            <a-col :span="24">
              <a-form-model-item label="楼层信息" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-row>
                  <a-col span="5" v-for="(item, index) in describeDetailList" :key="index">
                    <div style="display: flex" v-if="handle === 'add'">
                      <siyu-select
                        :show-search="true"
                        v-model="item.descriptionValue"
                        :options="item.desRangeList"
                        :labelKey="'name'"
                        :valueKey="'name'"
                      >
                        <div class="siyu-merchant-option" slot="menuItem" slot-scope="item">
                          <div class="siyu-merchant-option-name">{{ item.name }}</div>
                        </div>
                      </siyu-select>
                      <div class="mainInfo">{{ item.description }}</div>
                    </div>
                    <div style="display: flex" v-if="handle === 'edit'">
                      <a-select v-model="item.descriptionValue" style="width: 80%">
                        <a-select-option
                          :value="sitem"
                          v-for="(sitem, sindex) in item.desRangeList"
                          :key="sindex"
                          :disabled="handle === 'add' ? false : true"
                        >
                          {{ sitem }}
                        </a-select-option>
                      </a-select>
                      <span class="mainInfo">{{ item.description }}</span>
                    </div>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="房型">
                <div style="display: flex">
                  <a-select placeholder="几室" v-model="rowData.houseType" :disabled="handle === 'add' ? false : true">
                    <a-select-option :value="item.id" v-for="(item, index) in roomTypeList" :key="index">{{
                      item.title
                    }}</a-select-option>
                  </a-select>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="面积" prop="">
                <a-input
                  v-model="rowData.acreage"
                  placeholder="面积"
                  :disabled="handle === 'add' ? false : true"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="亲属" prop="">
                <a-select placeholder="请选择" v-model="rowData.relatives" :disabled="handle === 'add' ? false : true">
                  <a-select-option :value="item.id" v-for="(item, index) in relativesList" :key="index">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!-- <a-col :span="12">
              <a-form-model-item label="报价" :prop="dataList.length > 0 ? 'money' : ''">
                <a-input v-model="rowData.money" placeholder="报价"></a-input>
              </a-form-model-item>
            </a-col> -->
            <!-- <a-col :span="12">
              <a-form-model-item label="选择商品" prop="(gender">
                <a-button type="primary" @click="onOpenProduct">选择商品</a-button>
                <span
                  style="cursor: pointer; margin-left: 20px"
                  @click="onCheckPro()"
                  v-if="dataList && dataList.length > 0"
                  >当前选 {{ quantity }} 件商品</span
                >
              </a-form-model-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-model-item label="相关资料">
                <QiniuUpload
                  type="image"
                  :value.sync="customerFiles"
                  :size="5"
                  :disabled="handle === 'add' ? false : true"
                ></QiniuUpload>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-textarea
                  v-model="rowData.propertyRemark"
                  placeholder="请输入"
                  :auto-size="{ minRows: 3, maxRows: 21 }"
                  :disabled="handle === 'add' ? false : true"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="3" tab="标签" v-if="handle === 'edit'">
        <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 22 }">
          <a-form-model layout="horizontal" :label-col="{ span: 3 }" :wrapper-col="{ span: 22 }">
            <a-form-model-item label="客户标签" style="display: flex">
              <div class="cus_tag_outer" v-if="checkedTagList.length <= 0"><a-empty /></div>
              <span v-else>
                <!-- <label v-for="(item, index) in checkedTagList" :key="index" class="cus_tag_outer">
                  <div class="cus_tag_category">{{ item.tagType }}：</div>
                  <div class="cus_tag_list">
                    <div :key="index" class="cus_tag_item" v-for="(data, index) in item.tagList">
                      {{ data.tagName }} <a-icon type="close" @click="handleClose(data)" />
                    </div>
                  </div>
                </label> -->
                <span class="cus_tag_list">
                  <div :key="index" class="cus_tag_item" v-for="(data, index) in checkedTagList">
                    {{ data.tagName }} <a-icon type="close" @click="handleClose(data)" />
                  </div>
                </span>
              </span>
            </a-form-model-item>
            <a-form-model-item label="标签库" style="display: flex">
              <div class="cus_tag_outer" v-if="tagList.length <= 0"><a-empty /></div>
              <div v-else>
                <!-- <label v-for="(item, index) in tagList" :key="index" class="cus_tag_outer">
                  <div class="cus_tag_category">{{ item.tagType }}：</div>
                  <div class="cus_tag_list">
                    <div
                      :key="index"
                      class="cus_tag_item"
                      @click="onSelectTag(data)"
                      v-for="(data, index) in item.tagList"
                    >
                      {{ data.tagName }}
                    </div>
                  </div>addDealerCustomerInfo
                </label> -->
                <div class="cus_tag_list">
                  <div :key="index" class="cus_tag_item" @click="onSelectTag(data)" v-for="(data, index) in tagList">
                    {{ data.tagName }}
                  </div>
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="4" tab="任务" v-if="handle === 'edit'">
        <TaskList ref="TaskList" :cusName="rowData.name" :cusId="rowData.id" @onload="onCancel"></TaskList>
      </a-tab-pane>
      <a-tab-pane key="5" tab="历史跟进" v-if="handle === 'edit'">
        <HistoryList ref="HistoryList" :item="rowData" :cusId="rowData.id"></HistoryList>
      </a-tab-pane>
    </a-tabs>
    <div class="footer-btns" v-if="tabs == '1' || tabs == '2' || tabs == '3'">
      <!--      <a-button @click="onCancel" v-if="handle !='add' && tabs == '3'">取消</a-button>-->
      <a-button @click="onCancel">关闭</a-button>
      <!-- <a-button type="primary" :loading="btnsLoading" @click="toSubmit('')" v-if="rowData.propertyType == 1"
        >保存草稿</a-button
      > -->

      <a-button type="primary" :loading="btnsLoading" @click="toSubmit('save')" v-if="handle == 'add'">保存</a-button>
      <a-button type="primary" :loading="btnsLoading" @click="toSubmit('submit')" v-if="handle == 'add'"
        >申请预定单</a-button
      >
      <a-button type="primary" :loading="btnsLoading" @click="toSubmit('submit')" v-if="handle != 'add' && tabs == '3'"
        >保存</a-button
      >
      <!-- <a-button type="primary" :loading="btnsLoading" @click="toSubmit('')">申请预订单</a-button> -->
    </div>
    <ProductModal ref="ProductModal" @get-product-list="getProductList"></ProductModal>
    <CheckProduct ref="CheckProduct" :list="dataList"></CheckProduct>
    <ChooseCellAll ref="ChooseCellAll" @getData="getData"></ChooseCellAll>
  </a-modal>
</template>

<script>
import {
  addDealerCustomerInfo,
  selectByIdDealerCustomerInfo,
  editDealerCustomerInfo,
} from '../api/DealerCustomerInfoApi'
import SiyuSelect from '@/components/siyuUI/Select/Selector'
import ProductModal from './ProductModal.vue'
import TaskList from './TaskList.vue'
import HistoryList from './HistoryList.vue'
import CheckProduct from './CheckProduct.vue'
import ChooseCellAll from './ChooseCellAll.vue'

export default {
  components: {
    ProductModal,
    TaskList,
    HistoryList,
    CheckProduct,
    ChooseCellAll,
    SiyuSelect,
  },

  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      customerFiles: '',
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入客户姓名', trigger: 'blur' }],
        phone: [{ required: true, pattern: new RegExp(/^\d{11}$/), message: '请输入客户电话', trigger: 'blur' }],
        // customerType: [{ required: true, message: '请选择客户类型', trigger: 'change' }],
        customerSource: [{ required: true, message: '请选择客户来源', trigger: 'change' }],
        orderType: [{ required: true, message: '请选择订单类型', trigger: 'change' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        provinceId: [{ required: true, message: '请选择地址', trigger: 'change' }],
        birthday: [{ required: true, message: '请选择生日', trigger: 'change' }],
        hobby: [{ required: true, message: '请输入爱好', trigger: 'blur' }],
        propertyRemark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        money: [{ required: true, message: '请输入报价', trigger: 'blur' }],
        storeId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
        managerId: [{ required: true, message: '请选择员工', trigger: 'blur' }],
      },
      btnsLoading: false,
      customerTypeList: [], // 客户类型数据
      customerSourceList: [], // 客户来源
      quantity: 0,
      dataList: [],
      roomTypeList: [],
      roomList: [],
      toiletList: [],
      tagList: [],
      checkedTagList: [],
      tmpList: [],
      tabs: '1',
      relativesList: [],
      managerList: [],
      followerList: [],
      describeDetailList: [],
      pictureUrl: '',
      buildingTypeBindList: [],
      houseTypeList: [],
      orderStr: '',
    }
  },

  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.tagList = []
      this.tmpList = []
      this.checkedTagList = []
      this.getList()

      if (handle === 'add') {
        this.$set(this.rowData, 'gender', 0)
        this.$set(this.rowData, 'orderType', 1)
        this.$set(this.rowData, 'propertyType', 0)
        this.buildingTypeBindList = []
      } else if (handle === 'edit') {
        this.axios.get(`/api/dealer/customerTag/dealerCustomerTag/getAllTag`).then((res) => {
          this.tagList = res.body
        })

        selectByIdDealerCustomerInfo(row.id).then((res) => {
          this.getManager(res.body.storeId)
          this.rowData = res.body
          this.$set(this.rowData, 'buildingTypeId', res.body.buildingType)
          this.$set(this.rowData, 'gender', 0)
          let obj = {
            id: this.rowData.villageId,
            name: this.rowData.village,
          }
          this.getData(obj)
          if (res.body.propertyType == 1) {
            let arrPictures = res.body.dealerCustomerPictures.map((item) => {
              return item.picture
            })
            this.pictureUrl = arrPictures.join(',')
          }
          if (res.body.propertyType == 0) {
            this.describeDetailList = res.body.dealerResidentialQuarters
            
          }
          if (res.body.tags != null && res.body.tags.length > 0) {
            this.checkedTagList = res.body.tags
          }
          // this.dataList = res.body.dealerCustomerOrderDetails
          // this.quantity = res.body.dealerCustomerOrderDetails.length
        })
      }
    },

    // 获取小区信息
    getData(row) {
      this.houseTypeList = []
      if (this.handle === 'add') {
        this.$set(this.rowData, 'buildingTypeId', undefined)
      }
      this.buildingTypeBindList = []
      this.describeDetailList = []
      this.$set(this.rowData, 'village', row.name)
      this.$set(this.rowData, 'villageId', row.id)
      this.rowData.address = row.address
      this.axios.get(`/api/firm/community/selectById/${row.id}`).then((res) => {
        if (res.body.buildingTypeBindList) {
          // this.buildingTypeBindList = res.body.buildingTypeBindList
          // console.log("buildingTypeBindList",this.buildingTypeBindList)
          
          // 去重
          function unique(arr) {
              return Array.from(new Set(arr)) 
          }
          let BindList = res.body.buildingTypeBindList
            this.buildingTypeBindList =BindList.map(item=>{
             item.describeDetailList = item.describeDetailList.map(sitem=>{
               if(sitem.desRange.indexOf(',') !== -1){
                 sitem.desRange = unique(sitem.desRange.split(',')).join(',')
               } 
                return sitem 
             })
             return item
            })  
           
          let list = []
          this.buildingTypeBindList.forEach((item) => {
            if (item.buildingTypeName) {
              list.push({
                buildingTypeId: item.buildingTypeId,
                buildingTypeName: item.buildingTypeName,
              })
            }
          })
          this.houseTypeList = list
        }
      })
    },
    // 楼层
    changeHouse() {
      if (this.rowData.buildingTypeId != undefined) {

        let index = this.houseTypeList.findIndex((item) => {
          return item.buildingTypeId === this.rowData.buildingTypeId
        })
        this.describeDetailList = this.buildingTypeBindList[index].describeDetailList.map((item) => {

          if (item.desRange.indexOf('-') !== -1 && item.desRange.indexOf(',') === -1) {
            let itemArr = item.desRange.split('-')
            let desRangeList = []
            for (var i = itemArr[0] * 1; i < itemArr[1] * 1 + 1; i++) {
              desRangeList.push({ name: i + ''})
            }
            item.desRangeList = desRangeList

          } else {

            if (item.desRange.indexOf(',') !== -1) {
              let desRangeArr = item.desRange.split(',')
              let list = []
              for (var i = 0; i < desRangeArr.length; i++) {
                list.push({ name: desRangeArr[i] })
              }
              item.desRangeList = list
            } else {
              let desRangeList = item.desRange.split('')
              let listArr = []
              for (var i = 0; i < desRangeList.length; i++) {
                listArr.push({ name:desRangeList[i] })
              }
              item.desRangeList = listArr
            }
          }
          this.$set(item, 'descriptionValue', '')
          return item
        })
      } else {
        this.describeDetailList = []
      }
      console.log('this.describeDetailList', this.describeDetailList)
    },
    onShowChooseCell() {
      if (!this.rowData.provinceId) {
        return this.$message.warning('请选择物业地址')
      }
      let obj = {
        provinceId: this.rowData.provinceId,
        cityId: this.rowData.cityId,
        areaId: this.rowData.areaId,
        authStatus: 3
      }
      this.$refs.ChooseCellAll.getList(obj)
    },

    // 获取各个选择框数据
    getList() {
      this.axios.get('/api/base/system/dictionary/selectByCode/客户类型').then((res) => {
        // 客户类型
        this.customerTypeList = res.body.filter((item) => {
          return item.title == '意向客户' || item.title == '潜在客户'
        })
      })

      this.axios.get('/api/base/system/dictionary/selectByCode/房型').then((res) => {
        // 户型
        this.roomTypeList = res.body
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/亲属').then((res) => {
        // 户型
        this.relativesList = res.body
      })

      this.axios.get('/api/base/system/dictionary/selectByCode/厅').then((res) => {
        // 厅
        this.roomList = res.body
      })

      this.axios.get('/api/base/system/dictionary/selectByCode/卫').then((res) => {
        // 卫
        this.toiletList = res.body
      })

      // 获取客户来源
      this.axios.get(`/api/dealer/source/customerSource/list/table`).then((res) => {
        this.customerSourceList = res.body
      })

      // 获取门店
      this.axios.get(`/api/base/system/dealer/manager/getMinePower?flagPersonData=false`).then((res) => {
        this.followerList = res.body.powerVOList
      })
    },

    // 获取员工
    getManager(id) {
      this.axios.get(`/api/base/achievements/dealerAchievements/manager/choose?storeId=${id}`).then((res) => {
        this.managerList = res.body
      })
    },

    // 根据门店id获取该门店下的员工
    onStoreChange(val) {
      this.$refs.item.onFieldChange()
      this.getManager(val)
    },

    // 监听当前tabs页
    callback(key) {
      this.tabs = key
      if (key == 2) {
        this.$set(this.rowData, 'provinceId', this.rowData.provinceId)
        this.$set(this.rowData, 'cityId', this.rowData.cityId)
        this.$set(this.rowData, 'countyId', this.rowData.countyId)
        this.$set(this.rowData, 'address', this.rowData.address)
        this.$nextTick(() => {
          this.$refs.pcd.setPCD()
        })
      }

      if (key == 4) {
        this.$refs.TaskList.getList()
      }

      if (key == 5) {
        this.$refs.HistoryList.getList()
      }
    },

    // 获取选中商品
    getProductList(data) {
      const arr = new Map()

      let list = this.dataList.concat(data)
      this.dataList = list.filter((e) => !arr.has(e.id) && arr.set(e.id, 1))

      this.quantity = this.dataList.length
    },

    // 打开选择商品弹框
    onOpenProduct() {
      this.$refs.ProductModal.isShow()
    },

    // 打开查看当前选中商品弹框
    onCheckPro() {
      this.$refs.CheckProduct.isShow()
    },

    // 判断是否首次申请
    onIsFirstTime() {
      let requestData = {
        province: this.rowData.province,
        provinceId: this.rowData.provinceId,
        city: this.rowData.city,
        cityId: this.rowData.cityId,
        area: this.rowData.area,
        areaId: this.rowData.areaId,
        village: this.rowData.village,
        building: this.rowData.building,
        unit: this.rowData.unit,
        room: this.rowData.room,
      }

      if (!this.rowData.areaId) {
        return this.$message.warning('请选择物业地址')
      }

      if (
        this.rowData.village == undefined &&
        this.rowData.building == undefined &&
        this.rowData.room == undefined &&
        this.rowData.unit == undefined
      ) {
        return this.$message.warning('请填写小区')
      }

      this.axios.post(`/api/dealer/customer/dealerCustomerOrder/queryByAddress`, requestData).then((res) => {
        if (res.code === 200) {
          this.$set(this.rowData, 'orderStatus', res.body)
        }
      })
    },

    // 选择标签
    onSelectTag(checkedTag) {
      // this.tmpList.filter((e) => {
      //   if (e.id === checkedTag.tagTypeId) {
      //     e.tagList.push(checkedTag)
      //   }
      // })
      this.checkedTagList.push(checkedTag)
      // 去重
      let arr = new Map()
      this.checkedTagList = this.checkedTagList.filter((e) => !arr.has(e.id) && arr.set(e.id, 1))

      // 没有标签的数据被过滤
      // this.checkedTagList = this.tmpList.filter((e) => e.tagList.length > 0)
      // let tmpArr = []
      // this.checkedTagList.forEach((e) => {
      //   e.tagList.forEach((i) => {
      //     tmpArr.push(i.id)
      //   })
      // })
    },

    // 删除已选标签
    handleClose(removeTag) {
      // this.checkedTagList.forEach((e) => {
      //   if (e.id === removeTag.tagTypeId) {
      //     return e.tagList.splice(
      //       e.tagList.findIndex((e) => {
      //         return e.id === removeTag.id
      //       }),
      //       1
      //     )
      //   }
      // })

      this.checkedTagList.splice(
        this.checkedTagList.findIndex((e) => {
          return e.id == removeTag.id
        }),
        1
      )

      // 没有标签的数据被过滤
      // this.checkedTagList = this.tmpList.filter((e) => e.tagList.length > 0)
    },

    // 关闭弹框
    onCancel() {
      this.pictureUrl = ''
      this.rowData = {}
      this.describeDetailList = []
      this.visible = false
      this.btnsLoading = false
      this.$refs.form.resetFields()

      this.$emit('reload')
    },

    // 表单提交
    toSubmit(name) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.tags = this.checkedTagList
        this.rowData.dealerCustomerOrderDetails = this.dataList

        if (this.rowData.dealerCustomerOrderDetails.length > 0 && !this.rowData.money) {
          return this.$message.warning('请输入报价')
        }

        this.rowData.buildingType = this.rowData.buildingTypeId
        if (this.rowData.propertyType == 0) {
           if(!this.rowData.village){
             return this.$message.warning('请选择小区！')
           }
          if (this.rowData.buildingTypeId === undefined) {
          return this.$message.warning('请选择房屋类型！')
        }
        this.rowData.buildingType = this.rowData.buildingTypeId
          let dealerResidentialQuarters = []
          this.describeDetailList.forEach((item) => {
            let obj = {
              sort: item.sort,
              description: item.description,
              descriptionValue: item.descriptionValue,
            }
            dealerResidentialQuarters = dealerResidentialQuarters.concat(obj)
          })
          this.rowData.dealerResidentialQuarters = dealerResidentialQuarters
        }
        if (this.rowData.propertyType == 1 && this.pictureUrl == '') {
          return this.$message.warning('请选择房屋图片！')
        }
        if (this.rowData.propertyType == 1) {
          this.rowData.buildingFiles = this.pictureUrl.split(',')
        }
        this.rowData.customerFiles = this.customerFiles.split(',')
        this.btnsLoading = true

        if (name == 'save') {
          this.rowData.customerType = 0
        } else {
          this.rowData.customerType = 1
        }
        this.axios
          .post(`/api/dealer/dealer/customerInfo/addForDealer`, this.rowData)
          .then((res) => {
            this.$notification.success({ message: res.message })
            this.$emit('reload')
            this.visible = false
            this.rowData = {}
            this.pictureUrl = ''
            this.btnsLoading = false
            this.describeDetailList = []
          })
          .catch((err) => {
            this.btnsLoading = false
          })
          .finally(() => {
            this.btnsLoading = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mainInfo {
  margin: 0 0 2px 2px;
  width: 40px;
  font-size: 14px;
  color: #000000d9;
}
.cus_tag {
  &_outer {
    display: flex;
    align-items: center;
    margin-left: 95px;
    margin-bottom: 15px;
  }

  &_category {
    width: 7%;
  }
  &_list {
    width: 93%;
    display: flex;
    flex-wrap: wrap;
  }
  &_item {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    min-width: 80px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 15px;
  }
}
.footer-btns {
  text-align: right;
  margin-top: 20px;
}
.ant-tag {
  padding: 3px 10px;
}
.anticon svg {
  color: #33333359;
  font-size: 12px;
}
</style>
